<template>
  <h1>날씨 예보</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>