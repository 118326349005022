import { render, staticRenderFns } from "./fineDust.vue?vue&type=template&id=2a728892&scoped=true&"
import script from "./fineDust.vue?vue&type=script&lang=js&"
export * from "./fineDust.vue?vue&type=script&lang=js&"
import style0 from "./fineDust.vue?vue&type=style&index=0&id=2a728892&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a728892",
  null
  
)

export default component.exports